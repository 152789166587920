export var AFConstants;
(function (AFConstants) {
  AFConstants["onConversionDataSuccess"] = "onConversionDataSuccess";
  AFConstants["onConversionDataFail"] = "onConversionDataFail";
  AFConstants["onAppOpenAttribution"] = "onAppOpenAttribution";
  AFConstants["onAttributionFailure"] = "onAttributionFailure";
  AFConstants["CONVERSION_CALLBACK"] = "conversion_callback";
  AFConstants["OAOA_CALLBACK"] = "oaoa_callback";
  AFConstants["UDL_CALLBACK"] = "udl_callback";
})(AFConstants || (AFConstants = {}));
export var MediationNetwork;
(function (MediationNetwork) {
  MediationNetwork["IRONSOURCE"] = "ironsource";
  MediationNetwork["APPLOVIN_MAX"] = "applovin_max";
  MediationNetwork["GOOGLE_ADMOB"] = "google_admob";
  MediationNetwork["FYBER"] = "fyber";
  MediationNetwork["APPODEAL"] = "appodeal";
  MediationNetwork["ADMOST"] = "admost";
  MediationNetwork["TOPON"] = "topon";
  MediationNetwork["TRADPLUS"] = "tradplus";
  MediationNetwork["YANDEX"] = "yandex";
  MediationNetwork["CHARTBOOST"] = "chartboost";
  MediationNetwork["UNITY"] = "unity";
  MediationNetwork["TOPON_PTE"] = "topon_pte";
  MediationNetwork["CUSTOM_MEDIATION"] = "custom_mediation";
  MediationNetwork["DIRECT_MONETIZATION_NETWORK"] = "direct_monetization_network";
})(MediationNetwork || (MediationNetwork = {}));
