import { createReducer, on } from '@ngrx/store';
import { setUtmParams } from '@store/actions/analytics.actions';
import { analyticsInitialState } from '@store/state/analytics.state';

const analyticsReducerCreator = createReducer(
  analyticsInitialState,
  on(setUtmParams, (state, { utmParams }) => ({ ...state, utmParams })),
);

export function analyticsReducer(state, action) {
  return analyticsReducerCreator(state, action);
}