import { NgModule } from '@angular/core';
import { META_REDUCERS, provideStore } from '@ngrx/store';
import { userReducer } from '@store/reducers/user.reducers';
import { vehiclesReducer } from '@store/reducers/vehicles.reducers';
import { storageMetaReducer } from '@store/store-meta-reducer';
import { provideEffects } from '@ngrx/effects';
import { UserEffects } from '@store/effects/user.effects';
import { StorageService } from '@core/services/storage/storage.service';
import { appReducer } from '@store/reducers/app.reducers';
import { cmsReducer } from '@store/reducers/cms.reducers';
import { scheduleReducer } from '@store/reducers/schedule.reducer';
import { CmsEffects } from '@store/effects/cms.effects';
import { WorkshopEffects } from './effects/workshop.effects';
import { checkoutReducer } from './reducers/checkout.reducers';
import { CheckoutEffects } from './effects/checkout.effects';
import { VehiclesEffects } from './effects/vehicles.effects';
import { MaintenancesEffects } from './effects/maintenances.effects';
import { FilesFolderEffects } from './effects/files-folder.effects';
import { RefuelingEffects } from './effects/refueling.effects';
import { ScheduleEffects } from './effects/schedule.effects';
import { WINDOW } from 'custom-window';
import { analyticsReducer } from './reducers/analytics.reducers';

@NgModule({
  providers: [
    provideStore({
      app: appReducer,
      cms: cmsReducer,
      user: userReducer,
      vehicles: vehiclesReducer,
      checkout: checkoutReducer,
      schedule: scheduleReducer,
      analytics: analyticsReducer,
    }),
    provideEffects(
      CmsEffects,
      UserEffects,
      WorkshopEffects,
      CheckoutEffects,
      VehiclesEffects,
      MaintenancesEffects,
      FilesFolderEffects,
      RefuelingEffects,
      ScheduleEffects,
    ),
    {
      provide: META_REDUCERS,
      multi: true,
      useFactory: storageMetaReducer,
      deps: [
        StorageService,
        WINDOW,
      ]
    }
  ]
})
export class NgrxStoreModule { }
