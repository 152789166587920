import { UtmParams } from '@core/services/analytics/analytics.models';
import { createAction, props } from '@ngrx/store';

const ActionTypes = {
  SET_UTM_PARAMS: '[Analytics] set utm params',
};

export const setUtmParams = createAction(
  ActionTypes.SET_UTM_PARAMS,
  props<{ utmParams: UtmParams }>()
);