import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { StoreState } from '@store/state/store.state';
import { setUtmParams } from '@store/actions/analytics.actions';
import { UtmParamKey, UtmParamKeys, UtmParams } from './analytics.models';
import { Capacitor } from '@capacitor/core';
import { WINDOW } from 'custom-window';

@Injectable({ providedIn: 'root' })
export class AnalyticsService {

  /** Dependencies */
  private store = inject(Store<StoreState>);
  private window = inject(WINDOW);

  /**
   * Init analytics
   */
  public initialize() {
    // Init web utm params (native utm params are set in the deep link service)
    if (Capacitor.isNativePlatform()) { return; }
    const searchParams = new URLSearchParams(this.window.location.search);
    this.setUtmParams(searchParams);
  }

  /**
   * Set utm params
   */
  public setUtmParams(searchParams: URLSearchParams) {
    const utmParams: UtmParams = {};
    const utmKeys: UtmParamKey[] = Object.values(UtmParamKeys);
    utmKeys.forEach(key => {
      const value = searchParams.get(key) as UtmParamKey;
      if (value) { utmParams[key] = value; }
    });
    this.store.dispatch(setUtmParams({ utmParams }));
  }

}